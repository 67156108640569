"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
//@ts-ignore
const languageLoader = require.context('./assets/languages', true, /\.json$/, 'eager');
const probabilityRandomChoice = function (initial) {
    let cases = Object.assign({}, initial);
    let random = Math.floor(Math.random() * 100);
    for (let prob in cases) {
        if (Number(prob) >= random) {
            return cases[prob];
        }
    }
};
const caretPosition = function (input) {
    var start = input.selectionStart || 0, end = input.selectionEnd || 0, diff = end - start;
    // if (start >= 0 && start == end) {
    // do cursor position actions, example:
    // console.log('Cursor Position: ' + start);
    // } else if (start >= 0) {
    // do ranged select actions, example:
    // console.log('Cursor Position: ' + start + ' to ' + end + ' (' + diff + ' selected chars)');
    // }
    return [start, end];
};
function add(accumulator, a) {
    return accumulator + a;
}
const defaultState = {
    index: 0,
    currentWordIndex: 0,
    currentLetterIndex: undefined,
};
const defaultAvg = {
    letterPerSec: undefined,
    maxLetterPerSec: 0,
    wordPerSec: undefined,
    maxWordPerSec: 0,
    letterPerMin: undefined,
    maxLetterPerMin: 0,
    wordPerMin: undefined,
    maxWordPerMin: 0,
    avgLetterMin: [],
    avgWordMin: [],
    validWords: 0,
    validLetters: 0,
    totalWords: 0,
    totalLetters: 0,
    totalLettersWithSpace: 0,
};
class TypingtestCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, $locale, $filter, $q, $http, $window) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.$locale = $locale;
        this.$filter = $filter;
        this.$q = $q;
        this.$http = $http;
        this.$window = $window;
        this.defaultData = {
            lang: this.$locale.id,
            difficulty: 'simple',
            ttl: 120,
        };
        this.data = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || 'null') || ng.copy(this.defaultData);
        this.ttl = {
            10: `10 ${this.$filter('translate')('sec')}`,
            30: `30 ${this.$filter('translate')('sec')}`,
            60: `1 ${this.$filter('translate')('min')}`,
            120: `2 ${this.$filter('translate')('min')}`,
            180: `3 ${this.$filter('translate')('min')}`,
            300: `5 ${this.$filter('translate')('min')}`,
            600: `10 ${this.$filter('translate')('min')}`,
        };
        this.availLangs = languageLoader.keys().map((item) => item.replace('./', '').replace('.json', ''));
        this.text = [];
        this.state = ng.copy(defaultState);
        this.$input = document.getElementById('typetest-input');
        this.avg = ng.copy(defaultAvg);
        this.wordCount = 30;
        this.textAppendOffset = 3;
        this.offset = 0;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this.startWith = this.data.ttl;
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.timeRemaining = TypingtestCtrl._formatTime(this.data.ttl);
        this.langLoading = false;
        this._restart = () => {
            console.log('_restart');
            this._stopTimer.next();
            if (this.$game) {
                this.$game.unsubscribe();
            }
            this.state = ng.copy(defaultState);
            this.avg = ng.copy(defaultAvg);
            this.offset = 0;
            this.$input.value = "";
            this.text = this.makeText(this.wordCount, this.data.difficulty);
            this.startGame();
            this.startWith = this.data.ttl;
            this._startTimer.next(null);
        };
        this.$scope.$watch(() => {
            return {
                lang: this.data.lang,
                difficulty: this.data.difficulty,
                ttl: this.data.ttl,
            };
        }, (data) => {
            localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data));
        }, true);
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!this.timerWork) {
                currentTarget.dispatchEvent(e);
            }
            else {
                this._confirmNewGame((result) => {
                    currentTarget.dispatchEvent(e);
                });
            }
        })).subscribe();
    }
    loadLanguage(lang) {
        return __awaiter(this, void 0, void 0, function* () {
            return languageLoader(`./${lang}.json`).then((r) => {
                return this.$http.get(r.default, { cache: true }).then((resp) => {
                    if (resp.status == 200) {
                        this.currentLangData = resp.data;
                    }
                });
            });
        });
    }
    $onInit() {
        this.loadLanguage(this.data.lang).then(() => {
            this.$scope.$apply(() => {
                this.restart(true);
            });
        }).catch(() => {
            this.setLang('en');
        });
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = TypingtestCtrl._formatTime(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    $postLink() {
        var _a;
        (_a = this.$input) === null || _a === void 0 ? void 0 : _a.focus();
    }
    startGame() {
        if (this.$input) {
            const events = [
                (0, rxjs_1.fromEvent)(this.$input, 'keyup'),
                (0, rxjs_1.fromEvent)(this.$input, 'mouseup'),
                (0, rxjs_1.fromEvent)(this.$input, 'mouseleave'),
            ];
            const up = (0, rxjs_1.fromEvent)(this.$input, 'keyup');
            this.$game = up.pipe((0, operators_1.withLatestFrom)((0, rxjs_1.merge)(...events).pipe((0, operators_1.filter)(() => {
                return this.$input.value.replaceAll(' ', '') != '';
            }), (0, operators_1.map)((downEvent) => {
                return caretPosition(this.$input);
            }), (0, operators_1.tap)((value) => {
                this.$scope.$apply(() => {
                    this.state.start = value[0];
                    this.state.end = value[1];
                    this.state.currentLetterIndex = value[0] - 1;
                });
            }))), (0, operators_1.filter)(() => {
                return this.$input.value.replaceAll(' ', '') != '';
            }), (0, operators_1.map)((value) => {
                console.log(value);
                const keyUpEvent = value[0];
                const cursorPosition = value[1];
                return {
                    event: keyUpEvent,
                    // start: cursorPosition[0] - 1,
                    // end: cursorPosition[1],
                };
            }), (0, operators_1.filter)((value) => {
                const event = value.event;
                if (event instanceof KeyboardEvent) {
                    for (let code of ['Backspace', 'Numpad', 'Arrow', 'NumpadDecimal']) {
                        if (event.code.indexOf(code) == 0) {
                            return true;
                        }
                    }
                    for (let code of ['Space', 'Enter']) {
                        if (event.code.indexOf(code) == 0) {
                            return true;
                        }
                    }
                    if ((event.key != undefined) && (event.key.length == 1)) {
                        return true;
                    }
                }
                return false;
            }), (0, operators_1.map)((value, index) => {
                console.log(123);
                if (index == 0) {
                    this._startTimer.next(true);
                }
                const event = value.event;
                // console.log('filtered event', value)
                if ((this.$input.value == '')) {
                    this.state.currentLetterIndex = undefined;
                    // if (this.state.currentWordIndex == 0) {
                    //     this.state.currentLetterIndex = undefined
                    // } else {
                    // this.state.currentLetterIndex = 0
                    // }
                    this.text[this.state.currentWordIndex].letters.forEach((letter) => {
                        letter.correct = undefined;
                    });
                }
                else {
                    this.state.currentLetterIndex = this.state.start - 1;
                }
                if (['Space', 'Enter'].indexOf(event.code) > -1) {
                    const currentWord = this.text[this.state.currentWordIndex];
                    let endWordLetter = undefined;
                    if (currentWord) {
                        endWordLetter = {
                            wordIndex: currentWord.wordIndex,
                            correct: false,
                            symbol: event.code == 'Space' ? ' ' : ''
                        };
                        if (currentWord.letters.length == (this.$input.value.length - endWordLetter.symbol.length)) {
                            endWordLetter.correct = true;
                        }
                        currentWord.value = ng.copy(this.$input.value);
                    }
                    this.$input.value = "";
                    if (this.state.currentLetterIndex != undefined) {
                        this.state.currentWordIndex += 1;
                        for (let word of this.text) {
                            for (let letter of word.letters) {
                                if (word.wordIndex == this.state.currentWordIndex) {
                                    this.state.currentLetterIndex = undefined;
                                    break;
                                }
                                else if (word.wordIndex < this.state.currentWordIndex) {
                                    if (!letter.correct) {
                                        letter.correct = false;
                                    }
                                }
                            }
                        }
                    }
                    return endWordLetter;
                }
                else if (['Backspace', 'Delete'].indexOf(event.code) > -1) {
                    // this.state.currentLetterIndex = this.state.start -1
                    for (let word of this.text) {
                        let wordCorrect = true;
                        for (let letter of word.letters) {
                            if (word.wordIndex == this.state.currentWordIndex) {
                                if (this.$input.value[letter.letterIndex] != undefined) {
                                    letter.correct = wordCorrect && (letter.symbol == this.$input.value[letter.letterIndex]);
                                    if (!letter.correct)
                                        wordCorrect = false;
                                }
                                else {
                                    letter.correct = undefined;
                                }
                            }
                        }
                    }
                    return;
                }
                else if (event.key.length == 1) {
                    let currentLetter = undefined;
                    let currentWord = undefined;
                    for (let word of this.text) {
                        if (word.wordIndex == this.state.currentWordIndex) {
                            currentWord = word;
                            for (let letter of word.letters) {
                                if (letter.letterIndex == (this.state.currentLetterIndex || 0)) {
                                    currentLetter = letter;
                                    break;
                                }
                            }
                        }
                        if (currentLetter) {
                            break;
                        }
                    }
                    if (currentLetter && currentWord) {
                        this.state.currentLetterIndex = currentLetter.letterIndex;
                        currentLetter.correct = event.key == currentLetter.symbol;
                        for (let letter of currentWord.letters) {
                            if (this.$input.value[letter.letterIndex] != undefined) {
                                letter.correct = this.$input.value[letter.letterIndex] == letter.symbol;
                            }
                        }
                    }
                    else {
                        // this.state.currentWordIndex = undefined
                        // this.state.currentLetterIndex = undefined
                    }
                    if (currentWord) {
                        let wordCorrect = this.$input.value.length <= currentWord.letters.length;
                        currentWord.letters.forEach((letter) => {
                            if (letter.correct == false) {
                                wordCorrect = false;
                            }
                            else {
                                if (letter.correct != undefined) {
                                    letter.correct = wordCorrect;
                                }
                            }
                        });
                    }
                    return currentLetter;
                }
            }), (0, operators_1.tap)((currentLetter) => {
                this.$scope.$apply();
                for (let word of this.text) {
                    if (word.wordIndex < this.state.currentWordIndex) {
                        let wordCorrect = true;
                        word.letters.forEach((letter) => {
                            if (letter.correct == false) {
                                wordCorrect = false;
                            }
                            else {
                                letter.correct = wordCorrect;
                            }
                        });
                        word.correct = wordCorrect;
                        // if (currentLetter?.symbol == ' ') {
                        //     word.correct = wordCorrect && (word.value?.length == (word.letters.length + 1))
                        // } else {
                        //     word.correct = wordCorrect && (word.value?.length == word.letters.length)
                        // }
                    }
                }
                if (this.state.currentWordIndex >= (this.text.length - this.textAppendOffset)) {
                    this.offset = Math.max(this.state.currentWordIndex - this.textAppendOffset, 0);
                    this.text.push(...this.makeText(this.wordCount, this.data.difficulty, this.text.length));
                    // this.limit = this.offset + this.wordCount
                }
            }), (0, operators_1.filter)((currentLetter) => {
                return currentLetter ? currentLetter.correct : false;
            }), 
            // bufferTime(1000),
            // tap((validLetters: Array<ILetter>) => {
            //     this.$scope.$apply(() => {
            //         const letterPerSec = (validLetters || []).length
            //         this.avg.maxLetterPerSec = Math.max(letterPerSec, this.avg.maxLetterPerSec)
            //         this.avg.letterPerSec = letterPerSec
            //
            //         const validWordsIndexes = validLetters.map((letter: ILetter) => {
            //             const word = this.text[letter.wordIndex]
            //             return word.correct ? word.wordIndex : undefined
            //         }).filter((wordIndex: number | undefined) => wordIndex != undefined)
            //
            //         const wordPerSec = (new Set(validWordsIndexes)).size
            //         this.avg.maxWordPerSec = Math.max(wordPerSec, this.avg.maxWordPerSec)
            //         this.avg.wordPerSec = wordPerSec
            //     })
            // }),
            (0, operators_1.bufferTime)(Math.min(60, this.data.ttl) * 1000 - 10), (0, operators_1.tap)((validLetters) => {
                // console.log(
                //     items
                // )
                this.$scope.$apply(() => {
                    // const validLetters: Array<ILetter> = []
                    // items.forEach((item: any) => items.length ? validLetters.push(...item) : null)
                    const letterPerMin = Math.ceil((validLetters || []).length * (60 / Math.min(60, this.data.ttl)));
                    this.avg.maxLetterPerMin = Math.max(letterPerMin, this.avg.maxLetterPerMin);
                    this.avg.letterPerMin = letterPerMin;
                    this.avg.avgLetterMin.push(letterPerMin);
                    const validWordsIndexes = validLetters.map((letter) => {
                        const word = this.text[letter.wordIndex];
                        return word.correct ? word.wordIndex : undefined;
                    }).filter((wordIndex) => wordIndex != undefined);
                    const wordPerMin = Math.ceil((new Set(validWordsIndexes)).size * (60 / Math.min(60, this.data.ttl)));
                    this.avg.maxWordPerMin = Math.max(wordPerMin, this.avg.maxWordPerMin);
                    this.avg.wordPerMin = wordPerMin;
                    this.avg.avgWordMin.push(wordPerMin);
                });
            }), (0, operators_1.takeUntil)(this._stopTimer.pipe((0, operators_1.tap)(() => {
                console.log('stop Timer');
            }))), (0, operators_1.finalize)(() => {
                this.avg.validWords = this.text.map((word) => word.correct ? 1 : 0).reduce(add, 0);
                this.avg.validLetters = this.text.map((word) => {
                    let validEnterWordLength = word.letters.map((letter) => letter.correct ? 1 : 0).reduce(add, 0);
                    return validEnterWordLength == word.letters.length ? (validEnterWordLength + 1) : validEnterWordLength; // space
                }).reduce(add, 0);
                this.avg.totalWords = this.text.map((word) => word.correct != undefined ? 1 : 0).reduce(add, 0);
                this.avg.totalLetters = this.text.map((word, index) => {
                    return word.letters.map((letter) => letter.correct != undefined ? 1 : 0).reduce(add, 0);
                }).reduce(add, 0);
                this.avg.totalLettersWithSpace = this.text.map((word, index) => {
                    const enterWordLength = word.letters.map((letter) => letter.correct != undefined ? 1 : 0).reduce(add, 0);
                    return enterWordLength ? (enterWordLength + 1) : 0; // space
                }).reduce(add, 0) - 1;
                if (this.startWith == 0) {
                    this._endGame();
                }
                this.timerWork = false;
            })).subscribe();
        }
    }
    makeText(count, difficulty = 'simple', offset = 0) {
        let words = ng.copy(this.currentLangData.words);
        (0, utils_1.shuffle)(words);
        let result = [];
        let i = 0;
        let s = 0;
        let randomSentence = (0, utils_1.randomInt)(10, 25);
        function numberGenerator() {
            return probabilityRandomChoice({
                30: (0, utils_1.randomInt)(4, 6),
                80: (0, utils_1.randomInt)(10, 12),
                100: null
            });
        }
        function commaGenerator() {
            return probabilityRandomChoice({
                30: (0, utils_1.randomInt)(4, 8),
                80: (0, utils_1.randomInt)(10, 12),
                90: (0, utils_1.randomInt)(15, 18),
                100: null
            });
        }
        function endSentenceGenerator() {
            return probabilityRandomChoice({
                15: '?',
                30: '!',
                35: ';',
                100: '.'
            });
        }
        let commaIndex = commaGenerator();
        let numberIndex = numberGenerator();
        for (let word of words.splice(0, count)) {
            let letters = word.split('');
            i += 1;
            s += 1;
            if (difficulty == 'advanced') {
                if (s == 1)
                    letters[0] = letters[0].toUpperCase();
                result.push(...letters);
                if (randomSentence == s) {
                    result.push(endSentenceGenerator());
                    randomSentence = (0, utils_1.randomInt)(10, 25);
                    commaIndex = commaGenerator();
                    numberIndex = numberGenerator();
                    s = 0;
                }
                else {
                    if ((commaIndex == s) && (i != count)) {
                        result.push(',');
                        commaIndex = commaGenerator();
                    }
                    if (numberIndex == s) {
                        result.push(' ');
                        result.push((0, utils_1.randomInt)(0, 999).toString());
                        commaIndex = commaGenerator();
                        numberIndex = numberGenerator();
                    }
                }
            }
            else {
                result.push(...letters);
            }
            if (i != count)
                result.push(' ');
        }
        if (difficulty == 'advanced') {
            if (['.', ';', '!', '?'].indexOf(result[result.length - 1]) < 0) {
                result.push(endSentenceGenerator());
            }
            result[0] = result[0].toUpperCase();
        }
        let index = 0;
        return result.join('').split(' ').map((word, wordIndex) => {
            const _wordIndex = wordIndex + offset;
            return {
                wordIndex: _wordIndex,
                correct: undefined,
                letters: word.split('').map((symbol, letterIndex) => {
                    const letter = {
                        index: index,
                        correct: undefined,
                        letterIndex: letterIndex,
                        wordIndex: wordIndex,
                        symbol: symbol,
                    };
                    index += 1;
                    return letter;
                })
            };
        });
    }
    restart(direct = false) {
        var d = this.$q.defer();
        var p = new Promise((resolve, reject) => {
            if (this.timerWork) {
                this._confirmNewGame(() => {
                    this._stopTimer.next(null);
                    resolve(d);
                }).catch(() => {
                    d.reject();
                }).finally(() => {
                    this._resume.next('visible');
                });
            }
            else {
                resolve(d);
            }
            d.promise.then(() => {
                console.log('d resolve');
                this._restart();
            }, () => {
            });
        });
        return p.then((d) => {
            if (direct) {
                d.resolve();
            }
            return d;
        });
    }
    setDifficulty(difficulty) {
        this.restart().then((d) => {
            this.data.difficulty = difficulty;
            d.resolve();
        });
    }
    setLang(lang) {
        // this.restart().then((d: ng.IDeferred<any>) => {
        //     this.data.lang = lang
        //     d.reject()
        //     this.$window.location.reload()
        // })
        this.restart().then((d) => {
            this.langLoading = true;
            const safeLang = ng.copy(this.data.lang);
            this.loadLanguage(lang).then(() => {
                this.data.lang = lang;
                d.resolve();
                this.langLoading = false;
            }).catch((e) => {
                this.loadLanguage(safeLang).then(() => {
                    this.data.lang = safeLang;
                    d.resolve();
                    this.langLoading = false;
                });
            });
        });
    }
    setTtl(ttl) {
        this.restart().then((d) => {
            this.data.ttl = parseInt(ttl);
            this.timeRemaining = TypingtestCtrl._formatTime(this.data.ttl);
            this.startWith = this.data.ttl;
            d.resolve();
        });
    }
    pauseGame() {
        if (this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "alert-comp",
                scope: this.$scope,
                template: require('./paused.ng.html'),
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    _endGame() {
        this._stopTimer.next(null);
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                avgLetterMin: this.avg.avgLetterMin.reduce(add, 0) / this.avg.avgLetterMin.length,
                avgWordMin: this.avg.avgWordMin.reduce(add, 0) / this.avg.avgWordMin.length,
                accuracy: this.avg.totalWords ? (this.avg.validWords * 100 / this.avg.totalWords) : 0,
                avg: ng.copy(this.avg),
                cookieSettings: this.ConfigService.cookieSettings,
                timeRemaining: TypingtestCtrl._formatTime(this.data.ttl),
            }
        }).then((result) => {
            var _a;
            if (result == 'newGame') {
                this.restart(true);
                (_a = this.$input) === null || _a === void 0 ? void 0 : _a.focus();
            }
        });
    }
    _confirmNewGame(callback) {
        this._resume.next('hidden');
        return this.ModalServiceFactory.open({
            id: 'typingtest_new_game',
            component: "confirm-comp",
            scope: this.$scope,
            extraContext: {
                title: this.$filter('translate')('Progress in the current test will be lost'),
                settings: {}
            }
        }).then((result) => {
            if (result) {
                callback ? callback(result) : null;
            }
            else {
                // this._startTimer.next(null);
                // this._startTimer.next(true);
                throw { error: 'cancel' };
            }
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            console.log(v1, v2);
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = startWith - i;
            this.$timeout(() => {
                if (sec >= 0) {
                    this.timerWork = true;
                    this.startWith = sec;
                    this.timeRemaining = TypingtestCtrl._formatTime(sec);
                }
                else {
                    this.timerWork = false;
                    this._stopTimer.next();
                    // this.finished = true
                }
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    static _formatTime(sec) {
        if (sec >= 3600) {
            return new Date(sec * 1000).toISOString().substr(11, 8);
        }
        return new Date(sec * 1000).toISOString().substr(14, 5);
    }
}
TypingtestCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', '$locale', '$filter', '$q', '$http', '$window'];
const appModule = ng.module('app');
appModule.component('gameTypingtest', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TypingtestCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('typingtest/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
            highlight_letter: true
        });
    }]);
